@import '../theme.scss';

#navigationTop {
  padding: 0 $standard-padding-x;

  &.corporate {
    background-color: $color-red-light;
  }

  &.broker {
    background-color: $color-black;

    > .inner {
      .userTypes {
        .userType {
          &.active {
            a {
              color: $color-black;
              background-color: $color-white;
            }
          }
        }
      }
    }
  }

  > .inner {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-areas: 'uerTypes contact';
    justify-content: space-between;
    max-width: 1660px;
    margin: 0 auto;

    .userTypes {
      grid-template-columns: auto auto;
      grid-area: uerTypes;

      .userType {
        a {
          display: inline-block;
          font-size: 14px;
          line-height: 1.25;
          font-weight: 700;
          color: $color-white;
          padding: 1em;
          background-color: $color-transparent;
          transition: background 0.2s ease-in-out;
        }

        &.active {
          a {
            color: $color-red-light;
            background-color: $color-white;
          }
        }
      }
    }

    .contact {
      grid-template-columns: auto auto;
      grid-area: contact;
      grid-gap: 10px;

      .phone {
        a {
          display: inline-block;
          color: $color-white;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.02em;
          vertical-align: middle;

          span {
            display: inline-block;
            vertical-align: middle;
            padding-right: 5px;
          }
        }
      }

      .openHours {
        a {
          color: $color-white;
          font-size: 12px;
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: $screenBreak-mobile) {
  #navigationTop {
    > .inner {
      .contact {
        display: none;
      }
    }
  }
}
